import { TreeNode } from '../data/TreeNode';

export interface SearchBarProps {
  defaultPlaceholder: string;
  onSearchInputChange: (searchInput: string) => void;
}

export interface PredicateProps {
  name: string;
  description: string;
  setIsOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
  onSave: (
    targetList: TreeNode[],
    excludeList: TreeNode[],
    detailedTargetedNodes: Set<string>,
    detailedExcludedNodes: Set<string>
  ) => void;
  initialTargetList: TreeNode[];
  initialExcludeList: TreeNode[];
  initialDetailedTargetedNodes: Set<string>;
  initialDetailedExcludedNodes: Set<string>;
}

export interface PredicateValuePanelStylesProps {
  status: string;
  duration: number;
}

export interface PredicateGroupProps {
  name: string;
  emptyMessage: string;
  isSelected: boolean;
  onSelect: () => void;
  onRemoveAll: () => void;
  nodesList: TreeNode[];
  onRemoveItem: (node: TreeNode) => void;
}

export interface PredicateGroupsProps {
  name: string;
  selectedGroup: GroupType;
  onSelectGroup: (group: GroupType) => void;
  targetList: TreeNode[];
  excludeList: TreeNode[];
  onRemoveAllTargets: () => void;
  onRemoveAllExcludes: () => void;
  onRemoveItem: (node: TreeNode) => void;
}

export interface NavPredicateProps {
  name: string;
  description: string;
}

export interface ModeProps {
  description: string;
  onTargetClick: (node: TreeNode) => void;
  detailedTargetedNodes: Set<string>;
  detailedExcludedNodes: Set<string>;
  targetList: TreeNode[];
  selectedGroup: GroupType;
}

export interface TargetButtonProps {
  onClick: () => void;
  disabled: boolean;
}

export interface RemoveAllButtonProps {
  onClick: () => void;
}

export interface PredicateGroupItemProps {
  name: string;
  onClick: () => void;
}

export enum GroupType {
  Targeted = 'targeted',
  Excluded = 'excluded',
}
