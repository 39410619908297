import React from 'react';
import { LineItemElement } from '@amzn/d16g-rodeo-card-common';
import { LineItemProposalPageState } from 'src/model/LineItemPageState';
import { ComponentNames } from 'src/utils/MFEConstants';
import { get } from 'lodash-es';
import MockPageStateBuilder from 'src/mock/MockPageStateBuilder';
import {
  mockPageStateForLineItemStandardDisplay,
  mockPageStateForProposal,
} from 'src/mock/MockStandardDisplayLineItemState';
import '@amzn/unified-data-table-components/styles/css/udc-theme.css';
import mockedCustomAttributes from './mock/MockCustomElementAttributes.json';
import ShadowDOMWrapper from './ShadowDOMWrapper';
import ApiData from 'src/MFE/lineItem/model/api';
import NonCardAudienceTargetingContainer from 'src/MFE/lineItem/NonCardAudienceTargetingContainer';
import { getKatalLogger } from 'src/utils/KatalLogger';
import LocalizationWrapper from '../../Localization/LocalizationWrapper';
import { isWeblabActive, Weblabs } from 'src/utils/weblabUtil';
import PricingHelper from 'src/utils/PricingHelper';
import { FeeContextWrapper } from '@amzn/d16g-pricing-react-utils';

const CUSTOM_ATTRIBUTES = {
  advertiserId: 'advertiserId',
  clientId: 'clientId',
  entityId: 'entityId',
  lineItemId: 'lineItemId',
  campaignId: 'campaignId',
  country: 'country',
  audienceDiscoveryEndpoint: 'audienceDiscoveryEndpoint',
  audienceDiscoveryUrl: 'audienceDiscoveryUrl',
  audienceTaxonomyEndpoint: 'audienceTaxonomyEndpoint',
  segmentTargeting: 'segmentTargeting',
  marketplaceId: 'marketplaceId',
  locale: 'locale',
  csrfToken: 'csrfToken',
  personaBuilderUrl: 'personaBuilderUrl',
  personaBuilderTopOverlappingEndpoint: 'personaBuilderTopOverlappingEndpoint',
};

class NonCardAudienceTargetingMFE extends LineItemElement<LineItemProposalPageState> {
  connectedCallback() {
    super.connectedCallback();

    // With the removal of the legacy audience picker which made ajax calls,
    // these events were no longer dispatched which caused the forecasting widget to not render.
    // A temperory work around until resolved is to manually call these events
    document.dispatchEvent(new CustomEvent('ajaxSend'));
    document.dispatchEvent(new CustomEvent('ajaxComplete'));

    if (USE_MOCKS) {
      const PageStateBuilder = new MockPageStateBuilder();
      this.mfe = {
        pageState: PageStateBuilder.build(mockPageStateForProposal),
      };
      this.setAttribute(
        'customElementAttributes',
        JSON.stringify(mockedCustomAttributes)
      );
      this.dispatchEvent(new window.Event('MFEFrameworkInitialized'));
    }
  }

  renderMFE() {
    try {
      const that = this;
      const customElementAttributes = JSON.parse(
        this.getAttribute('customElementAttributes')
      );

      const getCustomElementAttribute = (elementName) => {
        if (USE_MOCKS) return get(customElementAttributes, elementName);

        return that.getAttribute(elementName);
      };

      const apiData: ApiData = {
        advertiserId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.advertiserId),
        orderId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.campaignId),
        entityId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.entityId),
        lineItemId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.lineItemId),
        marketplaceId: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.marketplaceId
        ),
        clientId: getCustomElementAttribute(CUSTOM_ATTRIBUTES.clientId),
        country: getCustomElementAttribute(CUSTOM_ATTRIBUTES.country),
        csrfToken: getCustomElementAttribute(CUSTOM_ATTRIBUTES.csrfToken),
        defaultFilters: [
          {
            field: 'status',
            values: ['Active'],
          },
        ],
        baseURL: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.audienceDiscoveryUrl
        ),
        endpoint: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.audienceDiscoveryEndpoint
        ),
        personaBuilderUrl: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.personaBuilderUrl
        ),
        personaBuilderTopOverlappingEndpoint: getCustomElementAttribute(
          CUSTOM_ATTRIBUTES.personaBuilderTopOverlappingEndpoint
        ),
      };

      let segmentTargeting = USE_MOCKS
        ? getCustomElementAttribute(CUSTOM_ATTRIBUTES.segmentTargeting)
        : JSON.parse(
            getCustomElementAttribute(CUSTOM_ATTRIBUTES.segmentTargeting)
          );

      segmentTargeting =
        PricingHelper.nullAllLegacyAudienceFees(segmentTargeting);

      return (
        <ShadowDOMWrapper>
          <FeeContextWrapper
            pricingApiContext={{
              baseUrl: apiData.baseUrl,
              country: apiData.country,
              marketplaceId: apiData.marketplaceId,
              csrfToken: apiData.csrfToken,
              currencyOfPreference: apiData.currencyOfPreference as any,
              fxConversionRate: apiData.fxConversionRate as any,
              currencyOfMarketplace: apiData.currencyOfPreference as any,
            }}
            pageState={this.mfe?.pageState}
          >
            <LocalizationWrapper pageState={this.mfe.pageState}>
              <NonCardAudienceTargetingContainer
                pageState={this.mfe?.pageState}
                advertiserId={getCustomElementAttribute(
                  CUSTOM_ATTRIBUTES.advertiserId
                )}
                apiData={apiData}
                segmentTargeting={segmentTargeting}
              />
            </LocalizationWrapper>
          </FeeContextWrapper>
        </ShadowDOMWrapper>
      );
    } catch (e: any) {
      const logger = getKatalLogger();
      logger.error(
        'Non-card Audience Picker MFE failed to render',
        e,
        this.mfe.pageState.getCurrentPageState()
      );
    }
  }
}

customElements.define(
  ComponentNames.NONCARD_AUDIENCE_TARGETING,
  NonCardAudienceTargetingMFE
);

export default NonCardAudienceTargetingMFE;
